<template>
  <div>
    <LayoutDataTable
      :isLoading="isLoading"
      :searchQuery="filter.searchQuery"
      :perPage="pagination.perPage"
      :meta="dataMeta"
      :currentPage="pagination.currentPage"
      :totals="pagination.totals"
      :searchPlaceholder="$t('lbl_search')"
      @setPerPage="
        (value) => ((pagination.perPage = value), (pagination.currentPage = 1))
      "
      @setCurrentPage="(value) => (pagination.currentPage = value)"
      @setSearchQuery="
        (value) => ((filter.searchQuery = value), (pagination.currentPage = 1))
      "
    >
      <template #default>
        <b-table
          ref="refCampaignReports"
          class="position-relative"
          striped
          hover
          responsive
          empty-text="Không có dữ liệu"
          :items="data"
          :fields="fieldsTable"
          @row-clicked="handleRowClick"
        >
          <template #cell(code)="data">
            <span>{{ appUtils.hiddenString(data.item.code) }}</span>
          </template>
          <template #cell(voucher_value)="data">
            <span class="text-nowrap">{{
              `${appUtils.numberFormat(
                data.item.voucher_value
              )} ${getVoucherType(data.item.voucher_type)}`
            }}</span>
          </template>
          <template #cell(campaign_start)="data">
            <span>
              {{ appUtils.formatDate(Number(data.item.campaign_start)) }}</span
            >
          </template>
          <template #cell(campaign_end)="data">
            <span>
              {{ appUtils.formatDate(Number(data.item.campaign_end)) }}</span
            >
          </template>
          <template #cell(is_redeemed)="data">
            <div :set="(statusRedeem = getStatusIsRedeemed(data.item))">
              <span :class="statusRedeem.color">{{ statusRedeem.text }}</span>
            </div>
          </template>
          <template #cell(updated_at)="data">
            <span>
              {{
                data.item.is_redeemed
                  ? appUtils.formatDateTime(data.item.updated_at)
                  : ""
              }}</span
            >
          </template>
        </b-table>
      </template>
    </LayoutDataTable>
  </div>
</template>

<script>
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import i18n from "@/libs/i18n";
import appUtils from "@/utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default {
  name: "CampaignReports",
  components: {
    LayoutDataTable,
  },
  directives: {},
  setup() {},
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refCampaignReports
        ? this.$refs?.refCampaignReports?.localItems?.length
        : 0;
      return {
        from:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.pagination.totals,
      };
    },
  },
  data() {
    return {
      appUtils,
      isLoading: false,
      filter: {
        searchQuery: "",
        from_date: "",
        to_date: "",
        returnResultStatus: "",
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        totals: 0,
        total_page: 0,
      },
      data: [],
      fieldsTable: [
        {
          key: "code",
          label: "CODE",
        },
        {
          key: "voucher_value",
          label: "Giá trị",
        },
        {
          key: "campaign_name",
          label: "Tên chương trình",
        },
        {
          key: "campaign_start",
          label: "Ngày bắt đầu",
        },
        {
          key: "campaign_end",
          label: "Ngày kết thúc",
        },
        {
          key: "voucher_group_name",
          label: "Nhóm voucher",
        },
        {
          key: "is_redeemed",
          label: "Tình trạng",
        },
        {
          key: "is_redeemed",
          label: "Tình trạng",
        },
        {
          key: "user_info.name",
          label: "Người quét",
        },
        {
          key: "updated_at",
          label: "Thời điểm quét",
        },
      ],
    };
  },
  async created() {
    await this.getReportsVoucher();
  },
  beforeRouteEnter(to, from, next) {
    to.meta.pageTitle = "campaigns.lbl_reports";
    next();
  },
  mounted() {
    this.$router.currentRoute.meta.pageTitle = "this.$i18n.t(this.pageTitle)";
  },
  watch: {
    "pagination.perPage"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.getReportsVoucher();
    },
    "pagination.currentPage"() {
      this.getReportsVoucher();
    },
    "filter.searchQuery"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.getReportsVoucher();
    },
    "filter.returnResultStatus"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.getReportsVoucher();
    },
  },
  methods: {
    async getReportsVoucher(ctx, callback) {
      const userData = this.appUtils.getLocalUser();
      const org_id = userData?.supplierInfo?.org_id;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getReportsVoucher({
          search: this.filter.searchQuery,
          page_size: this.pagination.perPage,
          page: this.pagination.currentPage,
          sort_by: "updated_at",
          sort: "desc",
          org_id,
        })
        .then((response) => {
          const voucherCodes = response?.data?.data || [];
          this.pagination.totals = response.data?.total;
          this.pagination.total_page = response.data?.total_page;
          this.data = response.data?.data;
          callback && callback(voucherCodes);
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleRowClick(item) {
      console.log(item);
    },
    getStatusIsRedeemed(data) {
      if (data.is_redeemed !== 0 && data.is_redeemed !== null) {
        return {
          text: "Đã sử dụng",
          color: "text-danger",
        };
      } else if (Number(data?.campaign_end) <= Date.now()) {
        return {
          text: "Đã hết hạn",
          color: "text-warning",
        };
      } else if (data?.status === 2) {
        return {
          text: "Đã huỷ",
          color: "text-danger",
        };
      } else {
        return {
          text: "Chưa sử dụng",
          color: "text-success",
        };
      }
    },
    getVoucherType(type) {
      if (type === 2) {
        return "%";
      }
      return "VNĐ";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-select {
  .vs__dropdown-toggle {
    height: 100%;
  }
}

.cs-tr-table {
  height: 40px !important;
}

.cs-header-col * {
  word-break: break-word !important;
}

.cs-bg-row {
  background-color: #e2f7fe !important;
}

::v-deep.el-table {
  .el-table__row {
    height: 40px;
  }
}
</style>
